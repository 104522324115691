// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {KGaZOTf3G: {hover: true}};

const cycleOrder = ["KGaZOTf3G"];

const serializationHash = "framer-BZd6s"

const variantClassNames = {KGaZOTf3G: "framer-v-1t8rykl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, hoverIcon, icon, id, link, width, ...props}) => { return {...props, G6_xbeBYS: icon ?? props.G6_xbeBYS ?? {src: "https://framerusercontent.com/images/VTMJnv0nNZyfXa9jJKIV1Lz50Q4.svg"}, LKseECf1W: link ?? props.LKseECf1W, TECxu1cVB: hoverIcon ?? props.TECxu1cVB ?? {src: "https://framerusercontent.com/images/0iBfqFAN1QyJaCb9FYLt0UuSXmA.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: {src: string; srcSet?: string};hoverIcon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, LKseECf1W, G6_xbeBYS, TECxu1cVB, GEPBWvvCRs2IfmimtW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KGaZOTf3G", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LKseECf1W}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", pixelHeight: 20, pixelWidth: 20, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(G6_xbeBYS)}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1t8rykl", className, classNames)} framer-1j5anuc`} data-framer-cursor={GEPBWvvCRs2IfmimtW} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KGaZOTf3G"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"KGaZOTf3G-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fill", pixelHeight: 20, pixelWidth: 20, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(TECxu1cVB)}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BZd6s.framer-1j5anuc, .framer-BZd6s .framer-1j5anuc { display: block; }", ".framer-BZd6s.framer-1t8rykl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px; height: 20px; justify-content: flex-end; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BZd6s.framer-1t8rykl { gap: 0px; } .framer-BZd6s.framer-1t8rykl > * { margin: 0px; margin-left: calc(20px / 2); margin-right: calc(20px / 2); } .framer-BZd6s.framer-1t8rykl > :first-child { margin-left: 0px; } .framer-BZd6s.framer-1t8rykl > :last-child { margin-right: 0px; } }", ".framer-BZd6s.framer-v-1t8rykl.hover.framer-1t8rykl { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"M1Wv94yW_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"LKseECf1W":"link","G6_xbeBYS":"icon","TECxu1cVB":"hoverIcon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraIwl5rkZk: React.ComponentType<Props> = withCSS(Component, css, "framer-BZd6s") as typeof Component;
export default FrameraIwl5rkZk;

FrameraIwl5rkZk.displayName = "Social Link Icon";

FrameraIwl5rkZk.defaultProps = {height: 20, width: 20};

addPropertyControls(FrameraIwl5rkZk, {LKseECf1W: {title: "Link", type: ControlType.Link}, G6_xbeBYS: {__defaultAssetReference: "data:framer/asset-reference,VTMJnv0nNZyfXa9jJKIV1Lz50Q4.svg?originalFilename=Line.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}, TECxu1cVB: {__defaultAssetReference: "data:framer/asset-reference,0iBfqFAN1QyJaCb9FYLt0UuSXmA.svg?originalFilename=Line.svg&preferredSize=auto", title: "Hover Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameraIwl5rkZk, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})